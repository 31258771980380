import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Table from "react-bootstrap/Table"
import Header from "../components/header"
import Footer from "../components/footer"

const SchedulePage = () => (
  <Layout>
    <SEO title="THACO | Schedule" name="schedule" />
    <Header page="schedule" />
    <section className="pb-5 text-white" style={{ paddingTop: 150 }}>
      <div className="container" style={{ maxWidth: 800 }}>
        <h2 className="text-center">
          <span className="bg-dark px-2 py-1">กำหนดการ</span>
        </h2>
        <p className="text-white mb-2 mt-5 text-center">
          วันที่และเวลาทั้งหมดจะอยู่ในเวลามาตรฐานประเทศไทย (GMT+7)
        </p>
        <Table className="text-white">
          <thead>
            <tr>
              <th>วันที่</th>
              <th>เวลา</th>
              <th>กิจกรรม</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>พุธที่ 7 ตุลาคม 2563</td>
              <td>21:30 น.</td>
              <td>เปิดรับสมัครผู้แต่งโจทย์และผู้เข้าแข่งขัน</td>
            </tr>
            <tr>
              <td>เสาร์ที่ 24 ตุลาคม 2563</td>
              <td>23:59 น.</td>
              <td>ปิดรับโจทย์</td>
            </tr>
            <tr>
              <td>อาทิตย์ที่ 25 ตุลาคม 2563</td>
              <td>
                19:00 น. - 23:59 น.
                <br />
                (ขึ้นอยู่กับจำนวนโจทย์)
              </td>
              <td>ประชุมคัดเลือกโจทย์</td>
            </tr>
            <tr>
              <td>ศุกร์ที่ 6 พฤศจิกายน 2563</td>
              <td>23:59 น.</td>
              <td>ปิดรับสมัครผู้เข้าแข่งขัน</td>
            </tr>
            <tr>
              <td>เสาร์ที่ 7 พฤศจิกายน 2563</td>
              <td>19:00 น. - 22:00 น.</td>
              <td>การแข่งขันวันที่ 1</td>
            </tr>
            <tr>
              <td>อาทิตย์ที่ 8 พฤศจิกายน 2563</td>
              <td>19:00 น. - 22:00 น.</td>
              <td>การแข่งขันวันที่ 2</td>
            </tr>
            <tr>
              <td>จันทร์ที่ 9 พฤศจิกายน 2563</td>
              <td></td>
              <td>ประชุมและตรวจสอบความเรียบร้อย</td>
            </tr>
            <tr>
              <td>อังคารที่ 10 พฤศจิกายน 2563</td>
              <td></td>
              <td>ประกาศผลการแข่งขันอย่างเป็นทางการ</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </section>
    <Footer />
  </Layout>
)

export default SchedulePage
